@import url(https://fonts.googleapis.com/css2?family=Roboto&family=Roboto+Mono&family=Poppins:wght@400;500;600;700&family=Montserrat:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', 'Segoe UI', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  text-decoration: none;
  max-width: 100vw;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 5px;
  position: absolute;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #fb9e12;
  border-radius: 5px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

body.modal-open {
  overflow: hidden;
}

